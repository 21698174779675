import React, { useCallback, useState } from "react"
import InnerPage from "../components/innerPage"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images";
import InnerHeader from "../components/InnerHeader"
import Breadcrumb from "../components/breadcrumb"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

const Gallerys = ({data}) => {
  const photos = [];

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const {image} = data.strapiGallery;

  image.forEach(img => {
    const {width, height, photo} = img;
    photo.forEach(single => {
      photos.push({
        src: single.url,
        width: width,
        height: height
      })
    })
  })

  return (
    <InnerPage>
      <Helmet>
        <title>Galerie</title>
        <meta name="Description" content="Galerie foto Restaurant Passion"/>
      </Helmet>
      <InnerHeader>
        <Breadcrumb label="Galerie foto"  />
      </InnerHeader>
      <div className="py5">
        <Gallery direction={"column"} margin={0} photos={photos} onClick={openLightbox} />
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </InnerPage>
)
}

export default Gallerys

export const pageQuery = graphql`
  query Gallery {
    strapiGallery {
      image {
        height
        width
        title
        photo {
          url
        }
      }
    }
  }
`
